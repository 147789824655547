import React, { FC, lazy } from 'react';
import { TableHeaderButton } from '../../../AbstractService';

interface TableHeaderButtonsListProps {
  buttons: TableHeaderButton[];
  dataStoreUrl?: string;
  defaultQuery?: string | undefined;
  appConfig: any;
}

const TableHeaderButtonsList: FC<TableHeaderButtonsListProps> = ({
  buttons,
  dataStoreUrl,
  defaultQuery,
  appConfig,
}) => {
  if (buttons.length) {
    buttons.unshift({ name: 'TableHeaderButtonDocs' });
  }
  const buttonsList = buttons.map(({ name }) =>
    lazy(() => import(`./buttons/${name}`)),
  );

  return (
    <>
      {buttonsList.map((Button, index) => (
        <Button
          key={index}
          dataStoreUrl={dataStoreUrl}
          defaultQuery={defaultQuery}
          appConfig={appConfig}
        />
      ))}
    </>
  );
};

export default TableHeaderButtonsList;
