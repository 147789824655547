import React, { Component } from 'react';
import { CONFIG_EXAMPLE, CONFIG_JSON_SCHEMA } from '../../constants';
import { AppConfig } from '../../../AbstractService';
import HttpDatastore from 'rollun-ts-datastore';
import Spinner from '../../../../UI/Spinner';
import { createDefaultServiceConfig } from '../../utils';
import { Query, Eq } from 'rollun-ts-rql';
import Dialog from '../../../../UI/Dialog';
import { Button, ConfirmButton } from '../../../../UI';

import JSONInput from 'react-json-editor-ajrm';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const locale = require('react-json-editor-ajrm/locale/en').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const validate = require('jsonschema').validate;

interface IState {
  error: string;
  loading: boolean;
  configValid: boolean;
  modalOpen: boolean;
}

interface IProps {
  createdResourceName: string;
  createdResourcePath: string;

  onConfigCreate(): void;
}

export interface OnJSONChangeProps {
  error: boolean | Record<string, unknown>;
  jsObject: Record<string, unknown> | undefined;
  lines: number;
  markupText: string;
  plainText: string;
}

const getInitState = () => ({
  error: '',
  loading: false,
  configValid: true,
  modalOpen: false,
});

class AddServiceConfig extends Component<IProps, IState> {
  serviceConfig: AppConfig;

  constructor(props: IProps) {
    super(props);
    this.serviceConfig = createDefaultServiceConfig(
      props.createdResourceName,
      props.createdResourcePath,
    );
    this.state = getInitState();
  }

  existedServiceId = '';

  frontConfigDataStore = new HttpDatastore(
    '/api/datastore/frontConfigDataStore',
  );

  _checkDataIfExist = (resource: string) => {
    return this.frontConfigDataStore.query(
      new Query({
        query: new Eq('resource', resource),
      }),
    );
  };

  _sendData = (resource: string) => {
    this.frontConfigDataStore
      .create({ resource, config: this.serviceConfig })
      .then(() => {
        this.setState({ loading: false });
        this.props.onConfigCreate();
      });
  };

  _updateData = () => {
    const { createdResourceName: resource } = this.props;
    this.frontConfigDataStore
      .update({
        id: this.existedServiceId,
        resource,
        config: this.serviceConfig,
      })
      .then(() => {
        this.setState({ loading: false });
        this.props.onConfigCreate();
      });
  };

  onServiceConfigCreate = () => {
    const { createdResourceName: resource, createdResourcePath } = this.props;
    if (!resource || !createdResourcePath)
      return this.setState({ error: 'Create Rule first!' });
    this.setState({ loading: true, error: '' });
    this._checkDataIfExist(resource)
      .then((res: any) => {
        if (res.length > 0) {
          this.existedServiceId = res[0].id;
          this.setState({ loading: false, modalOpen: true });
        } else {
          this._sendData(resource);
        }
      })
      .catch((err) =>
        this.setState({
          loading: false,
          error: `${err.status} ${err.statusText}`,
        }),
      );
  };

  render() {
    const { error, configValid, loading, modalOpen } = this.state;
    const { createdResourceName: resource } = this.props;

    if (loading) {
      return (
        <div className="m-5">
          <Spinner />
        </div>
      );
    }
    return (
      <div>
        <h5>Add Service config </h5>
        {!configValid || error ? (
          <p className="color-red my-2 font-weight-bold first-capitalize">
            {error}
          </p>
        ) : null}
        <p className="my-1 font-weight-bold">Resource name:</p>
        <input
          type="text"
          className="form-control"
          defaultValue={this.props.createdResourceName}
          disabled
        />
        <div className=" row mt-3">
          <div className="border col-md-6 col-sm-12">
            <JSONInput
              placeholder={this.serviceConfig}
              locale={locale}
              theme="light_mitsuketa_tribute"
              height="550px"
              width="100%"
              onChange={({ error, jsObject }: OnJSONChangeProps) => {
                if (error === false) {
                  const { errors, valid } = validate(
                    jsObject,
                    CONFIG_JSON_SCHEMA,
                  );
                  const errorMsg = errors.length > 0 ? errors[0].message : '';
                  this.serviceConfig = (jsObject as unknown) as AppConfig;
                  this.setState({ configValid: valid, error: errorMsg });
                }
              }}
            />
          </div>
          <div className="border col-md-6 col-sm-12">
            <p className="font-weight-bold m-0">Example config</p>
            <pre>{CONFIG_EXAMPLE}</pre>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {configValid ? (
            <Button
              className="my-3"
              color="primary"
              onClick={this.onServiceConfigCreate}
            >
              Create
            </Button>
          ) : (
            <ConfirmButton
              className="btn-warning my-3"
              modalBody="Config did not pass JSON schema validation, are You still want to save this config?"
            >
              Create
            </ConfirmButton>
          )}
        </div>
        <Dialog
          title="Confirm"
          isOpen={modalOpen}
          options={{ centered: true }}
          onClose={() => this.setState({ modalOpen: false })}
          onDecline={() => this.setState({ modalOpen: false })}
          onOk={() => {
            this.setState({ loading: true });
            this._updateData();
          }}
        >
          Route with this data:
          <div className="p-2 card border">
            <p className="ml-2">ID: {this.existedServiceId}</p>
            <p className="ml-2">Resource: {resource}</p>
          </div>
          exists. Update current service with your config
        </Dialog>
      </div>
    );
  }
}

export default AddServiceConfig;
