import React from 'react';
import { httpErrorHandlerPromised } from '../../../../utils/common.utils';
import { ErrorType } from '../../../../utils/common.types';
import HttpDatastore from 'rollun-ts-datastore';
import { FrontConfigRow } from '../../../RootApp/Root.app';
import { Eq, Query } from 'rollun-ts-rql';
import _ from 'lodash/fp';
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from '@material-ui/core';
import MuiIconButton from '../../../../UI/MuiIconButton';
import { ErrorView, Spinner } from '../../../../UI';
import MuiButton from '../../../../UI/MuiButton';
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import Description from '../../../../UI/Description';

interface IProps extends WithStyles {
  userNote?: string;
  isUserNoteShown: boolean;
  currentResourceName?: string;
  // example: config.appParams.gridParams.userNote
  configPath?: string;
}

interface IState {
  userNoteEdit: boolean;
  userNote: string | undefined;
  isDialogOpen: boolean;
  error: ErrorType | null;
  oldUserNote: string | undefined;
  loading: boolean;
}

const classes = (theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
});

class TableDescription extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      userNoteEdit: false,
      userNote: props.userNote,
      oldUserNote: props.userNote,
      isDialogOpen: false,
      error: null,
      loading: false,
    };
  }
  mdParser = new MarkdownIt();

  servicesConfigDS = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/frontConfigDataStore',
  );

  toggleUserNoteEdit = () => {
    this.setState(({ userNoteEdit }) => ({ userNoteEdit: !userNoteEdit }));
  };

  changeUserNote = (str: string) => {
    this.setState({
      userNote: str ?? this.state.userNote,
      error: null,
    });
  };

  setNewUserNote = async (resourceName: string, userNote: string) => {
    const [service] = await this.servicesConfigDS.query(
      new Query().setQuery(new Eq('resource', resourceName)),
    );

    const { configPath = 'config.appParams.gridParams.userNote' } = this.props;
    // creates new config json with updated userNote
    const newServiceConfig = _.update(configPath)(() => userNote)(service);
    await this.servicesConfigDS.update(newServiceConfig);
    this.setState({ oldUserNote: userNote });

    return newServiceConfig;
  };

  onUserNoteSubmit = async () => {
    this.setState({ loading: true });

    const { currentResourceName } = this.props;
    const { userNote } = this.state;

    if (!currentResourceName) {
      return this.setState({
        error: {
          code: 0,
          text: 'App resource name is not passed',
        },
        isDialogOpen: false,
      });
    }

    try {
      const newServiceConfig = await this.setNewUserNote(
        currentResourceName,
        userNote ?? '',
      );
      localStorage.setItem(
        `__SERVICE_CACHE__${currentResourceName}`,
        JSON.stringify(newServiceConfig),
      );

      // TODO: central cache storage
    } catch (e) {
      const { code, text } = await httpErrorHandlerPromised(e);

      this.setState({
        userNoteEdit: true,
        error: {
          code,
          text,
        },
        loading: false,
      });
      return;
    }

    this.setState(({ error }) => ({
      isDialogOpen: false,
      userNoteEdit: !!error,
      loading: false,
    }));
  };

  openConfirmDialog = () => {
    this.setState({ isDialogOpen: true });
  };

  onUserNoteDecline = () => {
    this.setState({
      isDialogOpen: false,
      userNote: this.state.oldUserNote,
    });
  };

  onUserNoteClose = () => {
    this.setState({
      isDialogOpen: false,
      userNoteEdit: false,
      userNote: this.state.oldUserNote,
    });
  };

  render() {
    const { isUserNoteShown, classes } = this.props;
    const { userNoteEdit, userNote, error, loading, isDialogOpen } = this.state;

    return isUserNoteShown ? (
      <Card className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <Box width="100%">
            {userNoteEdit ? (
              <>
                <MdEditor
                  value={userNote}
                  renderHTML={(text) => this.mdParser.render(text)}
                  onChange={({ text }) => this.changeUserNote(text)}
                />
              </>
            ) : userNote ? (
              <Description source={userNote} />
            ) : (
              <Typography color="textSecondary" variant="h6">
                No description
              </Typography>
            )}
          </Box>

          <Box display="flex" flexDirection="column" flex={1}>
            <MuiIconButton
              disabled={userNoteEdit}
              title="edit info"
              color="primary"
              onClick={this.toggleUserNoteEdit}
              iconName="edit"
            />
            {userNoteEdit ? (
              <Box display="flex" flexDirection="column">
                <MuiIconButton
                  title="edit info"
                  color="success"
                  onClick={this.openConfirmDialog}
                  iconName="check"
                />
                <MuiIconButton
                  title="cancel editing"
                  color="error"
                  onClick={this.onUserNoteClose}
                  iconName="ban"
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Dialog open={isDialogOpen} onClose={() => this.onUserNoteDecline()}>
          <DialogTitle>Want to change description?</DialogTitle>
          <DialogContent>
            {loading ? <Spinner /> : null}
            {!!error ? <ErrorView error={error} /> : null}
          </DialogContent>
          <DialogActions>
            <MuiButton
              color="success"
              onClick={this.onUserNoteSubmit}
              fullWidth
            >
              Confirm
            </MuiButton>
            <MuiButton color="error" onClick={this.onUserNoteDecline} fullWidth>
              Cancel
            </MuiButton>
          </DialogActions>
        </Dialog>
      </Card>
    ) : null;
  }
}

export default withStyles(classes)(TableDescription);
