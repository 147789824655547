import React, { PureComponent } from 'react';
import AbstractQueryNode from 'rollun-ts-rql/dist/nodes/AbstractQueryNode';
import { Button } from '../../../../../UI';
import { Query, And, Eq, Not } from 'rollun-ts-rql';
import AbstractLogicalNode from 'rollun-ts-rql/dist/nodes/logicalNodes/AbstractLogicalNode';
import _ from 'lodash';
import {
  replaceNodeAtPath,
  removeNodeAtPath,
  addNodeAtPath,
  getNodeFromPath,
} from '../../../../../utils/query.utils';
import BaseNodeEditor from '../BaseNodeEditor/BaseNodeEditor';

interface IProps {
  setQueryNode(node: AbstractQueryNode | undefined): void;

  fieldNames: Array<string>;
  currentQueryNode: AbstractQueryNode | undefined;
}

interface IState {
  queryNode: AbstractQueryNode | undefined;
  validationError: string | null;
  awaitingDrop: boolean;
}

class QueryNodeEditor extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      queryNode: props.currentQueryNode,
      validationError: null,
      awaitingDrop: false,
    };
  }

  // calls when there are no query nodes at all
  makeDefaultFirstNode = (name?: string) => {
    this.onQueryNodeChange(
      new Eq(name || this.props.fieldNames[0], 'search value'),
    );
  };

  onQueryNodeChange = (node?: AbstractQueryNode) => {
    this.setState({
      queryNode: node ? _.cloneDeep(node) : undefined,
    });
    this.props.setQueryNode(node);
  };

  wrapNodesInLogicalNode = (name?: string) => {
    const { queryNode } = this.state;
    if (!queryNode) return;
    this.onQueryNodeChange(
      new And([
        _.cloneDeep(queryNode),
        new Eq(name || this.props.fieldNames[0], 'search value'),
      ]),
    );
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ awaitingDrop: true });
    if (this.DRAG_LEAVE_TIMEOUT_ID) {
      clearTimeout(this.DRAG_LEAVE_TIMEOUT_ID);
      this.DRAG_LEAVE_TIMEOUT_ID = null;
    }
  };

  DRAG_LEAVE_TIMEOUT_ID: null | number = null;

  handleDragLeave = () => {
    this.DRAG_LEAVE_TIMEOUT_ID = window.setTimeout(() => {
      this.setState({ awaitingDrop: false });
    }, 200);
  };

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    const dataTransfer = e.dataTransfer;
    const nodeFieldName = dataTransfer.getData('nodefieldname');
    this.setState({ awaitingDrop: false });
    if (!this.state.queryNode) {
      this.makeDefaultFirstNode(nodeFieldName);
    } else {
      this.wrapNodesInLogicalNode(nodeFieldName);
    }
  };

  render() {
    const { queryNode, awaitingDrop } = this.state;

    if (!queryNode) {
      return (
        <div
          className="d-block m-2 p-2"
          onDragLeaveCapture={this.handleDragLeave}
          onDropCapture={this.handleDrop}
          onDragOverCapture={this.handleDragOver}
        >
          {awaitingDrop ? (
            <h4 className="text-center py-3 border-default-sm">Drop Here</h4>
          ) : (
            <Button
              id="add-query-node"
              color="primary"
              block
              onClick={() => this.makeDefaultFirstNode()}
            >
              Add query node (new)
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className="m-2 p-2">
        <div className="d-flex justify-content-between">
          <h5 className="py-2">Your query:</h5>
          <div id="control-buttons">
            {queryNode instanceof AbstractLogicalNode ? null : (
              <Button
                id="add"
                onClick={() => this.wrapNodesInLogicalNode()}
                className="my-2"
                color="success"
                size="sm"
              >
                Add
              </Button>
            )}
            <Button
              id="clear"
              onClick={() => this.onQueryNodeChange()}
              className="ml-2 my-2"
              color="danger"
              size="sm"
            >
              Clear
            </Button>
          </div>
        </div>
        <div className="query-editor">
          <BaseNodeEditor
            path={[0]}
            onNodeChange={this.replaceNodeAtPath}
            onNodeRemove={this.onNodeRemove}
            onNodeAdd={this.addNodeToLogicalNode}
            fieldNames={this.props.fieldNames}
            node={queryNode}
          />
        </div>
        {/* On awaitingDrop place holder, need to fix */}
        {/*{queryNode instanceof  AbstractLogicalNode*/}
        {/*	? <BaseNodeEditor path={[0]}*/}
        {/*										onNodeChange={this.replaceNodeAtPath}*/}
        {/*										onNodeRemove={this.onNodeRemove}*/}
        {/*										onNodeAdd={this.addNodeToLogicalNode}*/}
        {/*										fieldNames={this.props.fieldNames}*/}
        {/*										node={queryNode}/>*/}
        {/*	: <div onDragOver={this.handleDragOver}*/}
        {/*		   onDrop={this.handleDrop}*/}
        {/*		   onDragLeave={this.handleDragLeave}>*/}
        {/*		{awaitingDrop*/}
        {/*			? <BaseNodeEditor path={[0]}*/}
        {/*												isPreview*/}
        {/*												onNodeChange={this.replaceNodeAtPath}*/}
        {/*												onNodeRemove={this.onNodeRemove}*/}
        {/*												onNodeAdd={this.addNodeToLogicalNode}*/}
        {/*												fieldNames={this.props.fieldNames}*/}
        {/*												node={new And([queryNode, new Like('---', '---')])}/>*/}
        {/*			: <NonLogicalNodeEditor node={queryNode as AbstractArrayNode | AbstractScalarNode}*/}
        {/*									path={[0]}*/}
        {/*									onNodeChange={this.replaceNodeAtPath}*/}
        {/*									onNodeRemove={this.onNodeRemove}*/}
        {/*									fieldNames={this.props.fieldNames}/>}*/}
        {/*</div>*/}
        {/*}*/}
      </div>
    );
  }

  addNodeToLogicalNode = (path: Array<number>, node: AbstractQueryNode) => {
    const newQuery = addNodeAtPath(
      node,
      path,
      new Query({
        query: this.state.queryNode,
      }),
    );
    this.onQueryNodeChange(newQuery.queryNode);
  };

  replaceNodeAtPath = (path: Array<number>, newNode: AbstractQueryNode) => {
    const newQuery = replaceNodeAtPath(
      newNode,
      path,
      new Query({ query: this.state.queryNode }),
    );
    this.onQueryNodeChange(newQuery.queryNode);
  };

  onNodeRemove = (path: Array<number>) => {
    let query = new Query({
      query: this.state.queryNode,
    });
    const parentNode = getNodeFromPath(
      path.slice(0, path.length - 1),
      query,
    ) as AbstractLogicalNode;
    const currentNode = getNodeFromPath(path, query);
    // copy all inner nodes to parent in case of logical node
    if (
      currentNode instanceof AbstractLogicalNode &&
      currentNode.subNodes.length > 0
    ) {
      if (parentNode) {
        // add inner nodes to parent, and remove current node
        query = removeNodeAtPath(
          path,
          currentNode.subNodes.reduce((query, node) => {
            const newPath = path.slice(0, path.length - 1);
            return addNodeAtPath(node, newPath, query);
          }, query),
        );
      } else {
        // if no parent, just replace node with first child
        query.queryNode = currentNode.subNodes[0];
      }
    } else {
      query = removeNodeAtPath(path, query);
    }
    if (parentNode instanceof Not) {
      query = replaceNodeAtPath(
        getNodeFromPath(path, query),
        path.slice(0, path.length - 1),
        query,
      );
    }
    if (parentNode && parentNode.subNodes.length === 0) {
      query = removeNodeAtPath(path.slice(0, path.length - 1), query);
    }
    this.onQueryNodeChange(query.queryNode);
  };
}

export default QueryNodeEditor;
