enum DealType {
  Bag = 'bag',
  Order = 'order',
  Pickup = 'pickup',
  Dropship = 'dropship',
  ReturnOrder = 'return',
  ReturnPickup = 'returnPickup',
  ReturnBag = 'returnBag',
  ReturnDropship = 'returnDropship',
  Problem = 'problem',
  Fba = 'fba',
  Employees = 'employees',
  Client = 'client',
}

export const scenarios: Record<
  DealType,
  {
    name: string;
    url: string;
    id: string;
    delay?: number;
  }[]
> = {
  [DealType.Problem]: [],
  [DealType.Fba]: [],
  [DealType.ReturnOrder]: [
    {
      name: 'return deal creation',
      url: '',
      id: '21',
    },
  ],
  [DealType.ReturnPickup]: [
    {
      name: 'get tracknumber status',
      url: '',
      id: '10',
    },
    {
      name: 'return app add info',
      url: '',
      id: '12',
    },
  ],
  [DealType.ReturnDropship]: [
    {
      name: 'get tracknumber status',
      url: '',
      id: '10',
    },
  ],
  [DealType.ReturnBag]: [
    {
      name: 'bag filling app add info',
      url: '',
      id: '22',
    },
  ],
  [DealType.Pickup]: [
    {
      name: 'dimensions',
      url: '/api/webhook/CRMDealDimension',
      id: '11',
    },
    {
      name: 'return app add info',
      url: '/api/webhook/AddReturnInfo',
      id: '12',
    },
    {
      name: 'create rof reserve',
      url: '/api/webhook/CreateReserve',
      id: '13',
    },
    {
      name: 'delete rof reserve and subtract',
      url: '/api/webhook/DeleteReserveAndSubtract',
      id: '14',
    },
    {
      name: 'delete rof reserve',
      url: '/api/webhook/DeleteReserve',
      id: '15',
    },
    {
      name: 'tracknumber to MP',
      url: '/api/webhook/CRMTrackNumberToMarketplace',
      id: '8',
    },
    {
      id: '9',
      name: 'check tracknumber in parcel',
      url: '/api/webhook/TrackNumberToMegaplan',
    },
    {
      name: 'ship status',
      url: '/api/webhook/CRMTracknumberShipStatus',
      id: '10',
    },
    {
      name: 'labels purchase',
      url: 'http://rollun-crm/openapi/CRMEventHandler/v1',
      id: '23',
      delay: 5000,
    },
  ],
  [DealType.Order]: [
    {
      name: 'autohowtobuy 3',
      url: 'https://crm-callbacks.rollun.net/autohowtobuy3',
      id: '2',
    },
    {
      name: 'linked deal autocreation',
      url: '/api/webhook/CRMHowToBuy4Webhook',
      id: '3',
    },
    {
      name: 'cogs',
      url: '/api/webhook/CRMUpdateOrderCogsWebhook',
      id: '4',
    },
    {
      name: 'cogs new',
      url: '/api/webhook/CRMCalculateProfit',
      id: '5',
    },
    {
      name: 'check tracknumber on MP',
      url: '/api/webhook/CRMTrackNumberCheckOrder',
      id: '1',
    },
    {
      name: 'delivery check',
      url: 'http://rollun-crm/openapi/CRMEventHandler/v1',
      id: '25',
    },
  ],
  [DealType.Dropship]: [
    {
      // TuckerRockySupplier
      name: 'autobuy AU, PU, WPS',
      url: '/api/webhook/AutoBuyRu',
      id: '6',
    },
    {
      // TuckerRockySupplier
      name: 'get tracknumber AU, PU, WPS, TFT',
      url: '/api/webhook/OrderStatus',
      id: '7',
    },
    {
      name: 'tracknumber to MP',
      url: '/api/webhook/TrackNumberToMarketplace',
      id: '8',
    },
    {
      name: 'check tracknumber in parcel',
      url: '/api/webhook/TrackNumberToMegaplan',
      id: '9',
    },
    {
      name: 'ship status',
      url: '/api/webhook/TracknumberShipStatus',
      id: '10',
    },
  ],
  [DealType.Bag]: [
    {
      // TuckerRockySupplier
      name: 'autobuy PU, WPS',
      url: '/api/webhook/CRMAutoBuy',
      id: '6',
    },
    {
      name: 'RM autobuy',
      url: '/api/webhook/CRMPickUpAutoBuyRm',
      id: '16',
    },
    {
      name: 'labels creation Amazon',
      url: '/api/webhook/CRMAmazonLabelCreationWebhook',
      id: '17',
    },
    {
      name: 'get labels by bag Amazon',
      url: '/api/webhook/CRMSendBagLabels',
      id: '18',
    },
    {
      name: 'get tracknumber PU',
      url: '/api/webhook/CRMOrderStatus',
      id: '7',
    },
    {
      name: 'ship status',
      url: '/api/webhook/CRMTracknumberShipStatus',
      id: '10',
    },
    {
      name: 'prices to pickup orders',
      url: '/api/webhook/CRMAddPriceToPickup',
      id: '19',
    },
    {
      name: 'add products to bag',
      url: '/api/webhook/CRMAddProductsToBag',
      id: '20',
    },
    {
      name: 'labels merging',
      url: 'http://rollun-crm/openapi/CRMEventHandler/v1',
      id: '24',
    },
  ],
  [DealType.Employees]: [],
  [DealType.Client]: [],
};
