import React, { useContext, useState } from 'react';
import { Box, CircularProgress, Divider, Typography } from '@material-ui/core';
import JSONInput from 'react-json-editor-ajrm';
import { CONFIG_EXAMPLE, CONFIG_JSON_SCHEMA } from '../../constants';
import { OnJSONChangeProps } from '../CreateService/AddServiceConfig';
import { createDefaultServiceConfig } from '../../utils';
import { CreateServiceWithResourceContext } from './CreateServiceWithResource';
import useHttpDataStore from '../../../../hooks/useHttpDatastore';
import { AppConfig } from '../../../AbstractService';
import MuiButton from '../../../../UI/MuiButton';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const locale = require('react-json-editor-ajrm/locale/en').default;
// eslint-disable-next-line @typescript-eslint/no-var-requires
const validate = require('jsonschema').validate;

interface FrontConfig {
  id: string;
  resource: string;
  config: AppConfig;
}

const CreateConfig = () => {
  const [{ status }, dispatch] = useHttpDataStore<FrontConfig>(
    '/api/datastore/frontConfigDataStore',
  );
  const createService = useContext(CreateServiceWithResourceContext);
  const [configValid, setConfigValid] = useState(true);
  const [error, setError] = useState('');
  const [serviceConfig, setServiceConfig] = useState(
    createDefaultServiceConfig(
      createService?.serviceConfig?.name || '',
      createService?.serviceConfig?.path || '',
    ),
  );

  const handleCreateConfig = async () => {
    await dispatch('CREATE', {
      resource: createService?.serviceConfig?.name,
      config: serviceConfig,
    });
    createService?.setResourceId(createService?.resourceId, 'put-in-menu');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          Resource name: {createService?.serviceConfig?.name}
        </Typography>
        <MuiButton
          onClick={handleCreateConfig}
          color="success"
          disabled={status === 'loading'}
        >
          {status === 'loading' ? (
            <CircularProgress size="1.5rem" />
          ) : (
            'Create config'
          )}
        </MuiButton>
      </Box>
      <Box display="flex">
        {!configValid || error ? (
          <p className="color-red my-2 font-weight-bold first-capitalize">
            {error}
          </p>
        ) : null}
        <JSONInput
          placeholder={serviceConfig}
          locale={locale}
          theme="light_mitsuketa_tribute"
          height="550px"
          width="100%"
          onChange={({ error, jsObject }: OnJSONChangeProps) => {
            if (error === false) {
              const { errors, valid } = validate(jsObject, CONFIG_JSON_SCHEMA);
              const errorMsg = errors.length > 0 ? errors[0].message : '';
              setServiceConfig(serviceConfig);
              setError(errorMsg);
              setConfigValid(valid);
            }
          }}
        />
        <Divider orientation="horizontal" />
        <pre>{CONFIG_EXAMPLE}</pre>
      </Box>
    </Box>
  );
};

export default CreateConfig;
