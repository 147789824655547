import React, { Component, ReactNode, Suspense } from 'react';
import HomePage from './HomePage';
import { Route, RouteProps, Switch } from 'react-router-dom';
import HttpDatastore from 'rollun-ts-datastore';
import { Button, ErrorView, Spinner } from '../../UI';
import { AppConfig, AppType } from '../AbstractService';
import {
  cacheValidCheck,
  getUserIdentity,
  httpErrorHandler,
  saveBeforeLoginPath,
} from '../../utils/common.utils';
import { ErrorType } from '../../utils/common.types';
import Table from '../Table';
import ACLRulesEditor from '../ACLRulesEditor';
import BrandsMapper from '../BrandsMapper';
import EditableTree from '../EditableTree';
import CardPicker from '../CardPicker';
import ControlCenter from '../ControlCenter';
import ServiceConstructor from '../ServiceConstructor';
import ParcelApp from '../ParcelApp';
import CatalogUtils from '../CatalogUtils';
import HelpPage from '../HelpPage';
import PrepareListingsData from '../PrepareListingsData';
import EbayRefreshToken from '../EbayRefreshToken';
import BarcodeScannerDemo from '../BarcodeScannerDemo';
import AddNewUserApp from '../AddNewUser';
import MergingBrands from '../MergingBrands';
import MetricUI from '../MetricUI';
import ReturnApp from '../ReturnApp';
import CustomAlert, { AlertType } from '../../UI/CustomAlert';
import UnidentifiedProductsApp from '../UnidentifiedProducts';
import BagFillingApp from '../BagFillingApp';
import ImportantDocs from '../ImportantDocs';
import BarcodeApp from '../BarcodeApp';
import Login from '../Login';
import CrmClient from '../CRMClients';
import CRMBagDeal from '../CRMDeals/components/Bag';
import CRMOrderDeal from '../CRMDeals/components/Order';
import CRMDropshipDeal from '../CRMDeals/components/Dropship';
import CRMPickupDeal from '../CRMDeals/components/Pickup';
import ReturnAppTest from '../ReturnAppTest';
import { CRMReturnsDeal } from '../CRMDeals/components/Returns';
import { CRMReturnPickupDeal } from '../CRMDeals/components/ReturnPickup';
import { CRMReturnBagDeal } from '../CRMDeals/components/ReturnBag';
import { CRMReturnDropshipDeal } from '../CRMDeals/components/ReturnDropship';
import { CRMProblemDeal } from '../CRMDeals/components/Problem';
import CRMFbaDeal from '../CRMDeals/components/Fba';
import _ from 'lodash';
import { UsersControlPanel } from '../UsersControlPanel';
import SecretManager from '../SecretManager';
import CRMEmployees from '../CRMDeals/components/Employees';
import { getInheritedRoles, getRules } from '../Table/components/Table/utils';
import {
  CACHE_NAME_PREFIX,
  USER_FRONT_CONFIG,
  USER_UNAUTHORIZED,
} from '../Table/util/constants';
import { BpmnPage } from '../Bpmn';

export interface ServiceInfo {
  name: string;
  component: (
    props: RouteProps,
    service: { [key: string]: ServiceInfo },
  ) => ReactNode;
}

export type UserIdentity = { user: string; role: string; expires?: number };

export const handleLoginRedirect = async (
  userIdentity: UserIdentity,
  prevUserIdentity: UserIdentity,
) => {
  if (
    !userIdentity.user &&
    prevUserIdentity.user &&
    window.location.pathname !== '/login'
  ) {
    saveBeforeLoginPath();
    window.location.pathname = '/login';
  }
};

interface IState {
  configLoaded: boolean;
  error: ErrorType | null;
  userIdentity: UserIdentity;
  alertMsg: string;
  alertType: AlertType;
  alertOpen: boolean;
}

export interface FrontConfigRow {
  id: string;
  resource: string;
  config: AppConfig;
}

/**
 * Root Component for this application
 * It, depends on config, decides what component need to render current for this service
 */

interface AlertContext {
  isOpen: boolean;
  msg: string;
  type: AlertType;
  showAlert: (msg: string, type: AlertType) => void;
}

export const GlobalErrorContext = React.createContext<AlertContext | null>(
  null,
);

class RootApp extends Component<unknown, IState> {
  datastore = new HttpDatastore<FrontConfigRow>(
    '/api/datastore/UserFrontConfig',
  );
  getConfigInterval: ReturnType<typeof setInterval> | undefined;
  availableServices: { [key: string]: ServiceInfo } = {};

  state: IState = {
    configLoaded: false,
    error: null,
    userIdentity: {
      user: '',
      role: '',
      expires: 0,
    },
    alertMsg: '',
    alertOpen: false,
    alertType: 'error',
  };

  componentDidMount(): void {
    localStorage.setItem(USER_UNAUTHORIZED, 'false');

    this.getConfig().then();

    this.getConfigInterval = setInterval(() => {
      this.getConfig().then();
    }, 60000);
  }

  componentWillUnmount(): void {
    if (this.getConfigInterval) clearInterval(this.getConfigInterval);
  }

  getConfig = async () => {
    try {
      const isCacheValid = cacheValidCheck();
      console.log('isCacheValid', isCacheValid);

      const userIdentity = await getUserIdentity();
      console.log('userIdentity', userIdentity, this.state.userIdentity);

      if (_.isEqual(userIdentity, this.state.userIdentity)) return;
      getRules(isCacheValid);
      getInheritedRoles(isCacheValid);
      this.availableServices = await this.loadServicesConfigs(
        userIdentity,
        isCacheValid,
      );
      console.log(
        'this.availableServices',
        JSON.stringify(this.availableServices),
      );

      const prevUserIdentity = _.cloneDeep(this.state.userIdentity);
      this.setState({
        configLoaded: true,
        userIdentity,
      });
      handleLoginRedirect(userIdentity, prevUserIdentity);
    } catch (err) {
      httpErrorHandler(err, (code, text) =>
        this.setState({
          error: {
            code,
            text,
          },
        }),
      );
    }
  };

  /**
   * Fetches and caches all configs for all pages.
   * With fetched pages RootApp will build router
   */

  loadServicesConfigs = async (
    userIdentity: UserIdentity,
    isCacheValid = false,
  ) => {
    const serviceConfigs = localStorage.getItem(USER_FRONT_CONFIG);

    const res =
      serviceConfigs && isCacheValid && JSON.parse(serviceConfigs).length > 10
        ? JSON.parse(serviceConfigs)
        : await this.datastore.query();

    console.log(USER_FRONT_CONFIG, isCacheValid, JSON.stringify(res));
    console.log('serviceConfigs', serviceConfigs);

    if (
      !serviceConfigs ||
      !isCacheValid ||
      JSON.parse(serviceConfigs).length < 10
    ) {
      console.log(USER_FRONT_CONFIG, JSON.stringify(res));

      localStorage.setItem(USER_FRONT_CONFIG, JSON.stringify(res));
    }

    const services: { [key: string]: ServiceInfo } = {};
    for (let i = 0; i < res.length; i++) {
      const { config, resource } = res[i];
      localStorage.setItem(
        CACHE_NAME_PREFIX + resource,
        JSON.stringify(config),
      );
      if (!config) {
        console.warn(
          `Could not read config by [${resource}] resource`,
          i,
          res[i],
        );
        continue;
        // throw new Error(`Could not read config by [${resource}] resource`);
      }
      services[config.appPath] = {
        name: config.appName,
        component: this._getServiceApp(config.appType, resource, userIdentity),
      };
    }
    return services;
  };

  reloadHome = () => {
    this.setState({
      error: null,
      configLoaded: false,
    });
    this.getConfig().then();
  };

  render() {
    const { error, configLoaded } = this.state;

    if (error) {
      return (
        <div className="w-100 h-100 justify-content-center d-flex flex-column align-items-center">
          <div className="material-shadow px-4 pb-4 border-radius bg-white mx-2">
            <ErrorView error={error}>
              <Button color="primary" onClick={this.reloadHome}>
                Reload
              </Button>
            </ErrorView>
          </div>
        </div>
      );
    }

    if (!configLoaded) {
      return (
        <div className="w-100 h-100">
          <Spinner />
        </div>
      );
    }

    const userIdentityProdOrLocal = location.hostname.includes('rollun.net')
      ? this.state.userIdentity
      : {
          user: 'test',
          role: 'test',
        };

    return (
      <Suspense fallback={<Spinner />}>
        <GlobalErrorContext.Provider
          value={{
            isOpen: this.state.alertOpen,
            msg: this.state.alertMsg,
            type: 'error',
            showAlert: (msg: string, type: AlertType) => {
              this.setState({
                alertOpen: true,
                alertMsg: msg,
                alertType: type,
              });
            },
          }}
        >
          <CustomAlert
            msg={this.state.alertMsg}
            onClose={() =>
              this.setState({
                alertOpen: false,
                alertMsg: '',
              })
            }
            isOpen={this.state.alertOpen}
            alertType={this.state.alertType}
          />
          <Switch>
            <Route
              path="/bpmn"
              render={(routeProps) => (
                <BpmnPage
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BpmnEditor"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/dropships/:id"
              render={(routeProps) => (
                <CRMDropshipDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/pickups/:id"
              render={(routeProps) => (
                <CRMPickupDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/orders/:id"
              render={(routeProps) => (
                <CRMOrderDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/bags/:id"
              render={(routeProps) => (
                <CRMBagDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/returns/:id"
              render={(routeProps) => (
                <CRMReturnsDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/return-pickups/:id"
              render={(routeProps) => (
                <CRMReturnPickupDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/return-dropships/:id"
              render={(routeProps) => (
                <CRMReturnDropshipDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/return-bags/:id"
              render={(routeProps) => (
                <CRMReturnBagDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/problems/:id"
              render={(routeProps) => (
                <CRMProblemDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/deals/fba/:id"
              render={(routeProps) => (
                <CRMFbaDeal
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/employees/:id"
              render={(routeProps) => (
                <CRMEmployees
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/crm/clients/:id"
              render={(routeProps) => (
                <CrmClient
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            {Object.entries(this.availableServices).map(([path, info]) => (
              <Route
                key={path}
                path={path}
                render={(routeProps) =>
                  info.component(routeProps, this.availableServices)
                }
              />
            ))}
            <Route path="/login" render={() => <Login />} />
            <Route
              path="/important-docs"
              render={(routeProps) => (
                <ImportantDocs
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/scanner-test"
              render={(routeProps) => (
                <BarcodeScannerDemo
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="ScannerTest"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/return-app"
              render={(routeProps) => (
                <ReturnAppTest
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="ReturnApp"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/UsersControlPanel"
              render={(routeProps) => (
                <UsersControlPanel
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="/SecretsManager"
              render={(routeProps) => (
                <SecretManager
                  availableServices={this.availableServices}
                  userIdentity={userIdentityProdOrLocal}
                  resourceName="BrandsMapper"
                  history={routeProps.history}
                />
              )}
            />
            <Route
              path="*"
              render={() => <HomePage services={this.availableServices} />}
            />
          </Switch>
        </GlobalErrorContext.Provider>
      </Suspense>
    );
  }

  _getServiceApp = (
    appType: AppType,
    resource: string,
    userIdentity: UserIdentity,
  ): ((
    props: RouteProps,
    service: { [key: string]: ServiceInfo },
  ) => ReactNode) => {
    const appTypeToComponentMap: { [K in AppType]: any } = {
      grid: Table,
      BrandsMapper,
      ACLRulesEditor,
      EditableTree,
      BarcodeApp,
      cardPicker: CardPicker,
      ControlCenter,
      ServiceConstructor,
      ParcelApp,
      CatalogUtils,
      HelpPage,
      PrepareListingsData,
      EbayRefreshToken,
      BarcodeScannerDemo,
      AddNewUserApp,
      MergingBrands,
      MetricUI,
      ReturnApp,
      UnidentifiedProductsApp,
      BagFillingApp,
    };
    const ComponentToRender = appTypeToComponentMap[appType];
    if (ComponentToRender) {
      return (routeProps, services) => (
        <ComponentToRender
          {...{
            ...routeProps,
            resourceName: resource,
            availableServices: services,
            userIdentity,
          }}
        />
      );
    } else {
      return () => (
        <div className="w-100 h-100 justify-content-center d-flex flex-column align-items-center">
          <div className="material-shadow px-4 pb-4 border-radius bg-white mx-2">
            <ErrorView
              error={{
                code: 0,
                text: `App with name: ${resource} has wrong app type: ${appType}`,
              }}
            >
              <div className="d-flex justify-content-center">
                <Button
                  color="primary"
                  className="mr-2"
                  onClick={this.reloadHome}
                >
                  Reload
                </Button>
                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => (window.location.pathname = '/')}
                >
                  Home
                </Button>
              </div>
            </ErrorView>
          </div>
        </div>
      );
    }
  };
}

export default RootApp;
