import React, { FC, useEffect, useRef, useState } from 'react';
import MuiButton from '../../../UI/MuiButton';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';

const DialogWithPDF: FC<any> = ({
  item,
  open,
  setIsDialogOpen,
  setIsPdfdownloaded,
}) => {
  const [isBarcodeDownloaded, setIsBarcodeDownloaded] = useState(false);
  const [pdfBlob, setPdfBlob] = useState<null | Blob>(null);
  const { rid, csns, brand, title, mpn } = item;
  const csnsFormatted = Object.entries(csns).map(([, csn]) => csn);
  const iframeRef = useRef<null | HTMLIFrameElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean | null>(null);

  const zpl = `<pre>^XA</pre>
  <pre>^LL400</pre>
  <pre>^LH110,10</pre><br>
  <pre>^FO140,0^FX ======  Section with bar code. =====</pre>
  <pre>^BY3,3,80</pre>
  <pre>^FO480,40^BCR,80,N^FD${rid}^FS</pre><br>
  <pre>^FX ====== Section with RID,brand,mpn. =====</pre>
  <pre>^^FO100,10^CF0,80^FB400,1,0,C^FD${rid}^FS</pre>
  <pre>^FO30,85^CF0,55^FD${brand}^FS</pre>
  <pre>^FO30,140^CFA,20^FDMPN: ${mpn}^FS</pre><br>
  <pre>^FX ====== Section with csn. =====</pre>
  ${
    !!csnsFormatted[0]
      ? `<pre>^FO30,175^CFA,20^FDCSN: ${csnsFormatted[0]}^FS</pre>`
      : ``
  }
  ${
    !!csnsFormatted[1]
      ? `<pre>^FO30,210^CFA,20^FD     ${csnsFormatted[1]}^FS</pre>`
      : ``
  }
  ${
    !!csnsFormatted[2]
      ? `<pre>^FO30,245^CFA,20^FD     ${csnsFormatted[2]}^FS</pre>`
      : ``
  }
  ${
    !!csnsFormatted[3]
      ? `<pre>^FO30,285^CFA,20^FD     ${csnsFormatted[3]}^FS</pre>`
      : ``
  }<br/>
  <pre>^FX ====== Section with title. =====</pre>
  <pre>^FO30,325^CF0,20^FD^FB460,3^FD${title}^FS</pre>
  <pre>^XZ</pre>`;

  const apiZpl = `^XA
^LL400
^LH10,10
^FO140,0^FX ======  Section with bar code. =====
^BY3,3,80
^FO480,40^BCR,80,N^FD${rid}^FS

^^FO100,10^CF0,80^FB400,1,0,C^FD${rid}^FS
^FO30,85^CF0,40^FD${brand}^FS
^FO30,140^CFA,20^FDMPN: ${mpn}^FS

${!!csnsFormatted[0] ? `^FO30,175^CFA,20^FDCSN: ${csnsFormatted[0]}^FS` : ``}
${!!csnsFormatted[1] ? `^FO30,210^CFA,20^FD     ${csnsFormatted[1]}^FS` : ``}
${!!csnsFormatted[2] ? `^FO30,245^CFA,20^FD     ${csnsFormatted[2]}^FS` : ``}
${!!csnsFormatted[3] ? `^FO30,285^CFA,20^FD     ${csnsFormatted[3]}^FS` : ``}

^FO30,325^CF0,20^FD^FB460,3^FD${title}^FS
^XZ
`;

  useEffect(() => {
    try {
      console.log('zpl', { apiZpl, zpl });
      (async function () {
        setLoading(true);
        const response = await fetch(
          `https://api.labelary.com/v1/printers/8dpmm/labels/3x2/0/${apiZpl}`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/pdf',
            },
          },
        );
        console.log('response', response);
        if (!response.ok) {
          throw new Error('Failed to fetch PDF');
        }

        const pdfData = await response.blob();
        setPdfBlob(pdfData);
      })();
    } catch (e) {
      setError(
        `Error while fetching label, try again or report error: ${JSON.stringify(
          e,
        )}`,
      );
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, []);

  const handlePrintZpl = () => {
    const printWindow = window.open('', '_blank');
    printWindow?.document.open();
    printWindow?.document.write(`<html><body>${zpl}</body></html>`);
    printWindow?.document.close();
    printWindow?.addEventListener(
      'load',
      function (e) {
        printWindow.print();
      },
      false,
    );
    setIsBarcodeDownloaded(true);
  };

  const handleDownload = () => {
    if (pdfBlob) {
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${item.rid}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsBarcodeDownloaded(true);
    }
  };

  return (
    <Dialog
      className="paperWidthMd"
      open={open}
      onClose={() => setIsDialogOpen(false)}
    >
      <DialogTitle>You should print this file:</DialogTitle>
      <DialogContent>
        {!!error ? (
          <>{error}</>
        ) : !!pdfBlob ? (
          <iframe
            ref={iframeRef}
            src={URL.createObjectURL(pdfBlob) + '#page=1&view=FitH'}
            style={{
              width: '100%',
              height: '400px',
            }}
          ></iframe>
        ) : loading ? (
          <Typography>Loading</Typography>
        ) : (
          <>
            <Typography>Error while loading label pdf.</Typography>
            <Typography>
              Reload page or use "PRINT ZPL" button, if you have zpl printer.
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <MuiButton onClick={() => setIsDialogOpen(false)} color="error">
          Cancel
        </MuiButton>
        <MuiButton color="primary" onClick={handleDownload} disabled={!pdfBlob}>
          Download
        </MuiButton>
        <MuiButton color="primary" onClick={handlePrintZpl}>
          Print zpl
        </MuiButton>
        <MuiButton
          onClick={() => {
            setIsPdfdownloaded(true);
            setIsDialogOpen(false);
          }}
          color="success"
          disabled={!isBarcodeDownloaded}
        >
          I put a barcode
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogWithPDF;
