import React, { FC, FormEvent, useContext, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiTooltip from '../../../UI/MuiTooltip';
import MuiButton from '../../../UI/MuiButton';
import ConfirmAfterItem from './ConfirmAfterItem';
import ReturnAppContext from '../context';
import HttpDatastore from 'rollun-ts-datastore/dist';
import { httpErrorHandlerPromised } from '../../../utils/common.utils';
import { ReturnItem } from './ItemWasFound';
import {
  CatalogItem,
  DimensionItem,
  ReturnedItem,
} from '../../../hooks/datastores';
import Camera from 'react-html5-camera-photo';
import { useStorageByLocation } from '../../../hooks/useStorageByLocation';
import { BarcodeScanner } from '../../../lib/barcode-scanner/BarcodeScanner';
import { logger } from '../../../utils/logger';
import { useQueryParamState } from '../../../hooks/useQueryParamState';
import { Query, Eq } from 'rollun-ts-rql';
import { isJSON } from 'rollun-ts-utils';

interface SubmitItemProps {
  item: ReturnItem;
  isDamaged: boolean;
  handleNextItem?: () => void;
  processingRid?: string;
}

const ReturnsListDatastore = new HttpDatastore('/api/datastore/ReturnsList');
const ReturnsItemsListDatastore = new HttpDatastore<ReturnedItem>(
  '/api/datastore/ReturnsItemsList',
);
const ReturnProblemItemsDatastore = new HttpDatastore(
  '/api/datastore/ReturnProblemItems',
);

const useStyles = makeStyles(() => ({
  video: {
    '& video, img': {
      maxWidth: 300,
    },
  },
}));

const SubmitItem: FC<SubmitItemProps> = ({
  item,
  isDamaged,
  handleNextItem,
  processingRid,
}) => {
  const [problem, setProblem] = useState('');
  const [cameraError, setCameraError] = useState('');
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [takenImage, setTakenImage] = useState(false);
  const [image, setImage] = useState('');
  const getStorageByLocation = useStorageByLocation();
  const [problemItem, setProblemItem] = useState<
    (CatalogItem & DimensionItem) | null
  >(null);
  const context = useContext(ReturnAppContext);
  const classes = useStyles();
  const [, , removeProcessingRid] = useQueryParamState<string>('processingRid');

  const handleSubmitItem = async (type: 'new' | 'problem', code?: string) => {
    logger.info('SubmitItem.handleSubmitItem', {
      stack: new Error().stack,
      type,
      code,
    });
  };

  const execlyNotThatOldAboveFunc = async (
    type: 'new' | 'problem',
    code?: string,
  ) => {
    logger.info('SubmitItem.execlyNotThatOldAboveFunc', {
      stack: new Error().stack,
      type,
      code,
    });
    setError('');
    setLoading(true);
    try {
      logger.info('SubmitItemTest context', {
        context,
      });
      await ReturnsListDatastore.update({
        track_number: context.label,
        status: 'processed',
      });
      logger.info('after SubmitItemTest in SubmitItem', {
        context,
      });

      if (type === 'new') {
        await ReturnsItemsListDatastore.create({
          rid: item.id,
          sr_item_identifier: item.manufacturer_part_number,
          sr_item_identifier_type: 'mpn',
          track_number: context.label,
          storage: await getStorageByLocation(context.location),
          supplier: context.supplier,
        });
      } else {
        await ReturnProblemItemsDatastore.create({
          id: code,
          rid: item.id,
          sr_item_identifier: item.manufacturer_part_number,
          sr_item_identifier_type: 'mpn',
          track_number: context.label,
          status: problem,
          supplier: context.supplier,
          available: 'yes',
          storage: await getStorageByLocation(context.location),
          photo: image,
        });
      }

      if (
        !handleNextItem ||
        processingRid === '' ||
        processingRid === undefined
      ) {
        setIsOpen(true);
        return;
      }

      const [label] = await ReturnsListDatastore.query(
        new Query().setQuery(new Eq('track_number', context.label)),
      );

      const expectedRidsRaw = label.expected_rid;
      const expectedRids: string | string[] = isJSON(expectedRidsRaw)
        ? JSON.parse(expectedRidsRaw)
        : expectedRidsRaw;

      console.log(expectedRidsRaw, expectedRids, processingRid);

      if (!Array.isArray(expectedRids)) {
        setIsOpen(true);
        return;
      }

      if (+processingRid < expectedRids.length - 1) {
        handleNextItem();
        return;
      }

      if (+processingRid === expectedRids.length - 1) {
        removeProcessingRid();
      }

      setIsOpen(true);
    } catch (e) {
      const { text } = await httpErrorHandlerPromised(e);
      setError(text);
    } finally {
      setLoading(false);
    }
  };

  const handleProblemItem = () => {
    setProblemItem(item);
  };

  const handleTakePhoto = (image: string) => {
    setImage(image);
  };

  const handleCameraError = (error: Error) => {
    if (error.message.includes("'getSupportedConstraints' of undefined")) {
      setCameraError('Cannot use camera in unsecure environment');
      return;
    }

    setCameraError(error.message);
  };

  const handleSubmitItemPhoto = () => {
    setTakenImage(true);
  };

  const handleCodeSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const code = formData.get('code') as string;

    if (!code) {
      return setError('Could not submit empty code');
    }
    await execlyNotThatOldAboveFunc('problem', code);
  };

  return (
    <Grid container spacing={1}>
      {problemItem && (
        <>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Take a photo of the item
            </Typography>
          </Grid>
          {takenImage ? (
            <Grid item xs={12}>
              <form onSubmit={handleCodeSubmit}>
                <TextField
                  name="code"
                  fullWidth
                  variant="outlined"
                  label="Item code"
                />
                <Box marginTop={1}>
                  <MuiButton type="submit" fullWidth>
                    Submit code
                  </MuiButton>
                  {loading && <LinearProgress />}
                </Box>
              </form>
              <BarcodeScanner
                onResult={(code: string) =>
                  execlyNotThatOldAboveFunc('problem', code)
                }
              />
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.video}>
              {image ? (
                <>
                  <Box display="flex" justifyContent="center">
                    <img src={image} alt="test" />
                  </Box>
                  <Box marginTop={1}>
                    <MuiButton
                      fullWidth
                      color="warning"
                      onClick={() => setImage('')}
                    >
                      Retake
                    </MuiButton>
                  </Box>
                  <Box marginTop={1}>
                    <MuiButton
                      fullWidth
                      color="primary"
                      disabled={loading}
                      onClick={() => handleSubmitItemPhoto()}
                    >
                      Submit
                    </MuiButton>
                    {loading && <LinearProgress />}
                  </Box>
                </>
              ) : null}
              <Box maxWidth={500} display={image.length !== 0 ? 'none' : ''}>
                <Camera
                  isDisplayStartCameraError={false}
                  idealFacingMode="environment"
                  isImageMirror={false}
                  onTakePhoto={(dataUri) => handleTakePhoto(dataUri)}
                  onCameraError={(error) => handleCameraError(error)}
                />
              </Box>
            </Grid>
          )}
          {cameraError ? (
            <Grid item xs={12}>
              <Typography align="center" variant="h4" color="error">
                {cameraError}
              </Typography>
            </Grid>
          ) : null}
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" align="center" color="error">
          {error}
        </Typography>
      </Grid>
      <Grid item xs={12} key={item.id}>
        <Card elevation={3}>
          <CardActionArea>
            <CardMedia
              component="img"
              style={{
                height: 140,
              }}
              image={item.image}
              title="Items image"
            />
            <CardContent>
              <Typography variant="body1">Rollun ID: {item.id}</Typography>
              <Typography variant="body1">Brand: {item.brand_id}</Typography>
              <Typography variant="body1">
                Mpn: {item.manufacturer_part_number}
              </Typography>
              <MuiTooltip title="Dimensions: width * height * length * weight">
                <Typography variant="body1">
                  Dimensions: {item.width} * {item.height} * {item.length} *{' '}
                  {item.weight}
                </Typography>
              </MuiTooltip>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Box flexDirection="column" width="100%">
              <MuiButton
                fullWidth
                style={{ display: 'none' }}
                disabled={isDamaged || loading}
                color="success"
                onClick={() => {
                  logger.info('SubmitItem.MuiButton', {
                    stack: new Error().stack,
                  });
                  handleSubmitItem('new');
                }}
              >
                Received item is new
              </MuiButton>
              <button
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'green',
                  width: '100%',
                  height: '34px',
                  padding: '6px 16px',
                  fontSize: '0.875rem',
                  borderRadius: '4px',
                }}
                disabled={isDamaged || loading}
                onClick={() => {
                  logger.info('SubmitItem.button', {
                    stack: new Error().stack,
                  });
                  execlyNotThatOldAboveFunc('new');
                }}
              >
                THE ITEM RECEIVED IS NEW
              </button>
              <Box marginY={1} width="100%">
                <MuiButton
                  color="error"
                  fullWidth
                  disabled={problem.length === 0}
                  onClick={handleProblemItem}
                >
                  Problem with item
                </MuiButton>
              </Box>
              <TextField
                name="Describe problem"
                fullWidth
                variant="outlined"
                onChange={(e) => setProblem(e.target.value)}
              >
                Submit problem
              </TextField>
            </Box>
          </CardActions>
        </Card>
      </Grid>
      <ConfirmAfterItem
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        handleNotOk={() => context.setPage('returnLabels')}
        handleOk={() => context.setPage('foundLabel')}
      />
    </Grid>
  );
};

export default SubmitItem;
